import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Header1 from "../../components/header1"

var PrismicDOM = require("prismic-dom")

export const query = graphql`
  {
    allPrismicBlogPost(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        url
        uid
        last_publication_date
        data {
          title {
            text
          }
          author_name {
            text
          }
          hero_image {
            fixed(height: 240, width: 530) {
              ...GatsbyPrismicImageFixed
            }
          }
        }
      }
      totalCount
    }
  }
`

const BlogOverview = ({ data }) => {
  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <section className="bg-white py-6 mt-12">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800 bg-white">
          <Header1
            headerText="Industry insights from the past"
            widthClass="w-4/6"
          />

          <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl">
            <ul className="pt-20">
              {data.allPrismicBlogPost.nodes.map(node => {
                var date = PrismicDOM.Date(node.last_publication_date)

                var formattedDate = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(date)

                return (
                  <li key={node.data.title.text} className="py-2 list-disc">
                    <p>
                      <Link to={"/blog/" + node.uid}>
                        {formattedDate} - {node.data.title.text}
                      </Link>{" "}
                      <span className="text-base italic">
                        by {node.data.author_name.text}
                      </span>
                    </p>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogOverview
